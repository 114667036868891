import React from "react";
import { Link } from "gatsby";
import { RandomEmoji } from "../../common/RandomEmoji";

import * as styles from "./LessonsList.module.scss";

const LessonsList = ({ lessons }) => {
  if (lessons.length === 0) return false;

  return (
    <section className={styles.upcomming}>
      <h2 className="visually-hidden">
        <span>Web lessons:</span>
      </h2>
      <ul>
        {lessons.map(({ node }, index) => {
          // const date = node.startDate ? new Date(node.startDate) : null;
          // const dateString = date
          //   ? date.toLocaleString("default", {
          //       year: "numeric",
          //       month: "long",
          //       day: "numeric",
          //     })
          //   : null;
          return (
            <li key={index}>
              <RandomEmoji index={index} />{" "}
              <Link to={`/${node.slug.current}`}>
                {node.shortTitle || node.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export { LessonsList };
