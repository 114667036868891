import React from "react";
import { Link } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
// import { Map } from "../../common/Map";
import { LessonsList } from "../LessonsList";
import loadable from "@loadable/component";
import { BackgroundImage } from "./BackgroundImage";
// import { BackgroundVideo } from "./BackgroundVideo";

import * as styles from "./HomePage.module.scss";
import { RandomEmoji, randomEmoji } from "../../common/RandomEmoji";

const HomePage = ({ lessons }) => {
  // const image = home.artworks[0];
  // const BackgroundVideo = loadable(() => import("./BackgroundVideo"), {
  //   fallback: <div className="visually-hidden">loading background</div>,
  // });
  // console.log(lessons);
  return (
    <>
      <div className={styles.homeEmoji}>
        <RandomEmoji />
      </div>
      <div className={styles.home}>
        <h1>Learn the web:</h1>
        <div className={styles.strapLine}>
          <p>Designers can also be coders</p>
        </div>
        <div className={styles.homeInfo}>
          <LessonsList lessons={lessons} />
        </div>
      </div>
    </>
  );
};

export { HomePage };
