import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { HomePage } from "../components/pageLayout/HomePage";

export const biographyPages = graphql`
  query HomePageQuery {
    lessons: allSanitySlideshow(
      sort: { order: ASC, fields: order }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          description {
            _type
            _key
            children {
              _key
              _type
              marks
              text
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  // const exhibitions = [...data.solo.edges, ...data.group.edges];
  const lessons = data.lessons.edges;
  return (
    <>
      <Seo title="Learn HTML" />
      <HomePage lessons={lessons} />
    </>
  );
};

export default IndexPage;
